import axios from "axios";
import { deepCopy, newLogger } from "./util";
import eventBus from "@/utils/eventBus";

let logger = newLogger("Network");

let AUTH_HEADER = "X-Auth-Token";

let delay = (t) => {
  return new Promise((resolve) => {
    setTimeout(resolve, t);
  });
};

class Network {
  constructor() {
    this.router = null;
    this.store = null;
    this.http = null;
    this.excelNetwork = null;
    this.formDataNetwork = null;
    this.buefy = null;
  }

  /**
   * Called from app.vue on creation, sets the store reference and creates the axios instance
   */
  configure(store, router, buefy) {
    this.router = router;
    this.store = store;
    this.http = this.createAxiosInstance();
    this.excelNetwork = this.createExcelExportNetwork();
    this.formDataNetwork = this.createFormInstance();
    this.buefy = buefy;
  }

  getJwt() {
    return this.store.getters["session/jwt"];
  }

  createUnauthorizedAxiosInstance() {
    return axios.create({
      baseURL: "/api",
      timeout: 12000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // responseType: 'json',
    });
  }

  createAxiosInstance() {
    const http = axios.create({
      baseURL: "/api",
      timeout: 12000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // responseType: 'json',
    });
    http.interceptors.request.use(
      (config) => {
        let jwt = this.getJwt();
        if (!jwt || jwt === "") {
          throw "No JWT to use!";
        }
        config.headers[AUTH_HEADER] = jwt;
        // logger.debug("Added JWT to outgoing call.");
        return config;
      },
      (error) => {
        logger.error("AJAX call wasn't even sent.");
        logger.error(error);
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        let jwt = response.headers[AUTH_HEADER.toLowerCase()];
        if (jwt) {
          logger.debug("Refreshing JWT...");
          this.store.dispatch("session/refreshJwt", jwt);
        } else {
          // logger.debug("No JWT in response!");
        }
        return Promise.resolve(response);
      },
      (error) => {
        logger.warn("AJAX call resulted in an error response.");
        logger.warn(error);
        if (error.response.status === 400) {
          // bad request
          let errorMessage = error?.response?.data?.message ?? "Unknown error";
          const parts = errorMessage.split(";");
          errorMessage = parts[parts.length - 1].trim();
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(400)</strong> - ${errorMessage}`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        if (error.response.status === 401) {
          // unauthorized
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(401)</strong> - Please log in again!`,
            position: "is-bottom",
            type: "is-danger",
          });
          this.store.dispatch("session/boot");
          if (this.router.currentRoute.name !== "LoginPage")
            this.router
              .replace({
                name: "LoginPage",
                query: { returnUrl: this.router.currentRoute.fullPath },
              })
              .catch(() => {});
          eventBus.emitPageLoaded();
        }
        if (error.response.status === 403) {
          // forbidden
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(403)</strong> - You do not have access to this endpoint!`,
            position: "is-bottom",
            type: "is-danger",
          });
          this.router.push({ name: "HomePage" });
        }
        if (error.response.status === 500) {
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(500)</strong> - Internal server error, try again later`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        if (error.response.status === 503) {
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(503)</strong> - The module serving the request is currently unavailable, try again later`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        return Promise.reject(error);
      }
    );
    return http;
  }

  createFormInstance() {
    const http = axios.create({
      baseURL: "/api",
      timeout: 12000,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      // responseType: 'json',
    });
    http.interceptors.request.use(
      (config) => {
        let jwt = this.getJwt();
        if (!jwt || jwt === "") {
          throw "No JWT to use!";
        }
        config.headers[AUTH_HEADER] = jwt;
        // logger.debug("Added JWT to outgoing call.");
        return config;
      },
      (error) => {
        logger.error("AJAX call wasn't even sent.");
        logger.error(error);
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        let jwt = response.headers[AUTH_HEADER.toLowerCase()];
        if (jwt) {
          logger.debug("Refreshing JWT...");
          this.store.dispatch("session/refreshJwt", jwt);
        } else {
          // logger.debug("No JWT in response!");
        }
        return Promise.resolve(response);
      },
      (error) => {
        logger.warn("AJAX call resulted in an error response.");
        logger.warn(error);
        if (error.response.status === 400) {
          // bad request
          let errorMessage = error?.response?.data?.message ?? "Unknown error";
          const parts = errorMessage.split(";");
          errorMessage = parts[parts.length - 1].trim();
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(400)</strong> - ${errorMessage}`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        if (error.response.status === 401) {
          // unauthorized
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(401)</strong> - Please log in again!`,
            position: "is-bottom",
            type: "is-danger",
          });
          this.store.dispatch("session/boot");
          if (this.router.currentRoute.name !== "LoginPage")
            this.router
              .replace({
                name: "LoginPage",
                query: { returnUrl: this.router.currentRoute.fullPath },
              })
              .catch(() => {});
          eventBus.emitPageLoaded();
        }
        if (error.response.status === 403) {
          // forbidden
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(403)</strong>  -You do not have access to this endpoint!`,
            position: "is-bottom",
            type: "is-danger",
          });
          this.router.push({ name: "HomePage" });
        }
        if (error.response.status === 500) {
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(500)</strong> - Internal server error, try again later`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        if (error.response.status === 503) {
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(503)</strong> - The module serving the request is currently unavailable, try again later`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        return Promise.reject(error);
      }
    );
    return http;
  }

  createExcelExportNetwork() {
    const http = axios.create({
      baseURL: "/api",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    });
    http.interceptors.request.use(
      (config) => {
        let jwt = this.getJwt();
        if (!jwt || jwt === "") {
          throw "No JWT to use!";
        }
        config.headers[AUTH_HEADER] = jwt;
        logger.debug("Added JWT to outgoing call.");
        return config;
      },
      (error) => {
        logger.error("AJAX call wasn't even sent.");
        logger.error(error);
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        let jwt = response.headers[AUTH_HEADER.toLowerCase()];
        if (jwt) {
          logger.debug("Refreshing JWT...");
          this.store.dispatch("session/refreshJwt", jwt);
        } else {
          logger.debug("No JWT in response!");
        }
        return Promise.resolve(response);
      },
      (error) => {
        logger.warn("AJAX call resulted in an error response.");
        logger.warn(error);
        if (error.response.status === 400) {
          // bad request
          let errorMessage = error?.response?.data?.message ?? "Unknown error";
          const parts = errorMessage.split(";");
          errorMessage = parts[parts.length - 1].trim();
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(400)</strong> - ${errorMessage}`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        if (error.response.status === 401) {
          this.store.dispatch("session/boot");
          if (this.router.currentRoute.name !== "LoginPage")
            this.router
              .replace({
                name: "LoginPage",
                query: { returnUrl: this.router.currentRoute.fullPath },
              })
              .catch(() => {});
          eventBus.emitPageLoaded();
        }
        if (error.response.status === 403) {
          this.router.push({ name: "HomePage" });
        }
        if (error.response.status === 500) {
          this.buefy.toast.open({
            duration: 5000,
            message: `<strong>(500)</strong> - Internal server error, try again later`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        if (error.response.status === 503) {
          this.buefy.toast.open({
            duration: 5000,
            message: `<b>(503)</b> - The module serving the request is currently unavailable, try again later`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        return Promise.reject(error);
      }
    );
    return http;
  }

  async connection() {
    if (this.store.getters["session/isReady"]) return this.http;
    console.log("No JWT detected, establishing an interval to wait for it...");
    let rounds = 0;
    while (rounds < 6 && !this.store.getters["session/isReady"]) {
      console.log("Waiting for session establishment...");
      await delay(500);
      rounds++;
    }
    return this.http;
  }

  async formDataConnection() {
    if (this.store.getters["session/isReady"]) return this.formDataNetwork;
    console.log("No JWT detected, establishing an interval to wait for it...");
    let rounds = 0;
    while (rounds < 6 && !this.store.getters["session/isReady"]) {
      console.log("Waiting for session establishment...");
      await delay(500);
      rounds++;
    }
    return this.formDataNetwork;
  }

  async excelConnection() {
    if (this.store.getters["session/isReady"]) return this.excelNetwork;
    console.log("No JWT detected, establishing an interval to wait for it...");
    let rounds = 0;
    while (rounds < 6 && !this.store.getters["session/isReady"]) {
      console.log("Waiting for session establishment...");
      await delay(500);
      rounds++;
    }
    return this.excelNetwork;
  }
}

// singleton
export default new Network();
